import * as React from "react";

import {
  apiPlugin,
  storyblokInit,
  useStoryblokState,
} from "gatsby-source-storyblok";

import Layout from "../components/layout";

storyblokInit({
  accessToken: process.env.GATSBY_SB_PREVIEW_TOKEN,
  use: [apiPlugin],
});

const Page = ({ pageContext }) => {
  let story = pageContext.story;
  story = useStoryblokState(story);

  return <Layout content={story.content} path={story.full_slug} />;
};

export default Page;
